import React from 'react'
import { Head, Menubar, UpcomingProject } from '@components'
import { graphql, useStaticQuery } from 'gatsby'
import * as st from '@assets/styl/enterprises.module.styl'
import cn from 'classnames'
import { useLanguage } from '../../hooks'
import { translatePath } from '../../translations'
import img01 from '@assets/img/lancamento-01.jpg'
import img02 from '@assets/img/lancamento-02.jpg'
import { UpcomingProjectProps } from 'src/components/UpcomingProject'

export default () => {
  const items: UpcomingProjectProps[] = useStaticQuery(graphql`
    query {
      allReleases {
        nodes {
          facade
          facadeMobile
          facadeHover
          facadeHome
          facadeHomeMobile
          facadeHomeHover
          name
          title
          description
          neighborhood
          city
          room
          tag
          url
          feature
        }
      }
    }
  `).allReleases.nodes

  const { t } = useLanguage()

  return (
    <>
      <Head
        pathname={translatePath('/empreendimentos/lancamentos/')}
        title={t('Releases.titlePage')}
        description={t('Releases.descriptionPage')}
      />

      <Menubar pathname="/empreendimentos/lancamentos/" position="absolute" />

      <section className={cn(st.hero, 'heroScroll')}>
        <h1>
          {t('Releases.title.1')} <b>{t('Releases.title.2')}</b>
        </h1>
      </section>

      <section className={st.core}>
        <div className={st.header}>
          <h2>{t('Releases.subtitle')}</h2>
        </div>
        <div className={st.projects}>
          {items.map((item, key) => (
            <UpcomingProject key={key} {...item} />
          ))}
        </div>
      </section>

      <section className={`${st.knowMore} ${items.length % 2 ? st.oddCount : ''}`}>
        <div className={st.container}>
          <div className={st.header}>
            <h2>{t('Releases.subtitle1')}</h2>
          </div>
          <div className={st.actions}>
            <a
              className="btn"
              href={translatePath('/empreendimentos/entregues/')}
              rel="noopener noreferrer"
            >
              {t('Releases.button.1')}
            </a>
            <a
              className="btn"
              href={translatePath('https://www.dimasconstrucoes.com.br/revendas/')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Releases.button.2')}
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
